<template>
  <div>
    <h4 :class="`kda-ts-${g_bLowerBr ? '20' : '24'}pt wt-bold nunito mb-1 mb-md-3`">
      {{ strDetailsTitle }}
    </h4>

    <p v-if="strDetailsText" :class="`kda-ts-${g_bLowerBr ? '14' : '16'}pt nunito ln-170`">
      {{ strDetailsText }}
    </p>

    <ul
      v-if="m_arrDetailsPoints.length > 0"
      class="pl-5 pl-sm-8 d-flex flex-column"
      :style="{ gap: g_bLowerBr ? '4px' : '12px' }"
    >
      <li
        v-for="(point, i) in m_arrDetailsPoints"
        :key="'career-detail-point-' + i"
        :class="`kda-ts-${g_bLowerBr ? '14' : '16'}pt nunito ln-170`"
      >
        {{ point.strText }}
      </li>
    </ul>
  </div>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDACareerDetailsCard',

  mixins: [BaseBreakpoint],

  props: {
    strDetailsTitle: {
      type: String,
      default: ''
    },
    strDetailsText: {
      type: String,
      default: ''
    },
    arrDetailsPoints: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    m_arrDetailsPoints() {
      const array = [];
      this.arrDetailsPoints.forEach((point) => {
        if (point) {
          const obj = {
            strText: point
          };
          array.push(obj);
        }
      });
      return array;
    }
  }
};
</script>
